import { action, redirect } from '@solidjs/router';
import { z } from 'zod';
import { decode } from 'decode-formdata';
import { EndpointByMethod } from './__generated__/validators';
import { revalidate } from './cache';
import { getApiClient } from './client';
import { ApiError, UnknownApiError } from './errors';
import type { CacheablePaths } from './cache';
import type { Action } from '@solidjs/router';
import type { paths } from './__generated__/database';
import type { FetchResponse, FetchOptions } from 'openapi-fetch';
import type { RequiredKeysOf, FilterKeys, PathsWithMethod } from 'openapi-typescript-helpers';

type Options<Res extends Record<string, unknown>, Input> = {
	revalidate?:
		| CacheablePaths
		| Array<CacheablePaths>
		| ((data: Res, input: FormData) => Array<CacheablePaths> | undefined);
	redirect?: (data: Res, input: FormData) => undefined | string;
	onSuccess?: (data: Res, input: FormData) => Promise<void>;
	transformer?: (input: FormData, out: Partial<Input>) => Partial<Input>;
};

export function clientAction<
	Method extends 'PATCH' | 'POST' | 'PUT' | 'DELETE' | 'patch' | 'post' | 'put' | 'delete',
	Path extends PathsWithMethod<paths, Lowercase<Method>>,
	Res extends FetchResponse<
		Lowercase<Method> extends infer T
			? T extends 'delete' | 'DELETE'
				? T extends keyof paths[Path]
					? Record<string, string>
					: never
				: T extends Lowercase<'PATCH' | 'POST' | 'PUT' | 'patch' | 'post' | 'put'>
					? T extends keyof paths[Path]
						? paths[Path][T]
						: never
					: never
			: never,
		'json',
		'application/json'
	>,
	Rest extends RequiredKeysOf<Omit<FetchOptions<FilterKeys<paths[Path], Lowercase<Method>>>, 'body'>> extends never
		? Omit<FetchOptions<FilterKeys<paths[Path], Lowercase<Method>>>, 'body'> | undefined
		: Omit<FetchOptions<FilterKeys<paths[Path], Lowercase<Method>>>, 'body'>,
>(
	method: Method,
	path: Path,
	rest?: Rest | ((data: FormData) => Rest),
	opts?: Options<
		NonNullable<Exclude<Res['data'], 'undefined'>>,
		FetchOptions<FilterKeys<paths[Path], Lowercase<Method>>>['body']
	>,
	key?: string,
): Action<[FormData], NonNullable<Exclude<Res['data'], 'undefined'>>> {
	return action(
		async (data) => {
			const client = getApiClient();
			let body = { ...decode(data), ...(opts?.transformer ? opts.transformer(data, {}) : {}) };

			// @ts-expect-error because these don't actually strictly overlap stupidly
			const validator = EndpointByMethod[method.toLowerCase() as Lowercase<Method>][path];
			if (validator.parameters instanceof z.ZodObject) {
				const res = validator.parameters.shape.body?.safeParse(body);

				body = (res?.data as Record<string, string>) ?? body;
			}

			// @ts-expect-error TS doesn't want you to be clever
			const res = await client[method.toUpperCase()](path, {
				body,
				...(typeof rest === 'function' ? rest(data) : rest),
			} as FetchOptions<FilterKeys<paths[Path], Lowercase<Method>>>);

			if (res.error) {
				throw new ApiError(res.response.status, res.error);
			}

			if (res.response.status !== 204 && !res.data) {
				throw new UnknownApiError();
			}

			const resData = res.data ?? {};

			if (opts?.onSuccess) {
				await opts.onSuccess(resData, data);
			}

			const revalidateData = typeof opts?.revalidate === 'function' ? opts.revalidate(resData, data) : opts?.revalidate;

			if (revalidateData) {
				await revalidate(revalidateData, true);
			}

			const redirectUrl = opts?.redirect ? opts.redirect(resData, data) : undefined;
			if (redirectUrl) {
				await new Promise<void>((resolve) => {
					setTimeout(() => resolve(), 100);
				});
				throw redirect(redirectUrl);
			}

			return resData;
		},
		key ?? `${method}-${path}`,
	);
}
